import * as yup from 'yup'
import { yupResolver } from '@hookform/resolvers/yup'
import type { ValidationMode } from 'react-hook-form'

import { Visitor } from '../../../models/visitor-model'

export const MSG_REQUIRED_FIELD = 'O campo é obrigatório.'

export const visitoryDefaultValues: Visitor = {
  id: '',
  name: '',
  email: '',
  phone: '',
  cpf: '',
  marketingOptIn: true,
  createdAt: '',
  group: ''
}

const mode: keyof ValidationMode = 'all'
export const visitorySchemaValidation = yup.object({
  id: yup.string(),
  name: yup.string(),
  email: yup.string(),
  phone: yup.string(),
  cpf: yup.string(),
  marketingOptIn: yup.boolean(),
  createdAt: yup.string(),
  group: yup.string().required(MSG_REQUIRED_FIELD),
})

export const visitoryUseFormArgs = {
  mode,
  defaultValues: visitoryDefaultValues,
  resolver: yupResolver(visitorySchemaValidation),
}

export interface CompanyTypes
  extends yup.InferType<typeof visitorySchemaValidation> { }
