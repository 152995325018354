import { useContext, useEffect, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { Box, Container, Typography } from '@mui/material'
import DomainIcon from '@mui/icons-material/Domain'

import useProtectedPage from 'src/hooks/useProtectedPage'
import { Visitor } from 'src/models/visitor-model'
import { EditVisitorForm } from './components/Form/EditVisitorForm'
import { editVisitor, getVisitorById } from 'src/service/visitorsService'
import { goToVisitors } from 'src/routes/coordinator'
import { AlertContext } from 'src/context/AlertContext'
import { ROUTES } from 'src/routes/Router'
import { AlertComponent } from 'src/components/Alert/AlertComponent'
import { getRole } from 'src/service/authService'
import { useGlobalSelectedCompany } from 'src/context/GlobalSelectedCompanyContext'

interface Props {
  isViewMode?: boolean
  viewId?: any
}
const EditVisitor = ({ isViewMode, viewId }: Props) => {
  useProtectedPage()
  const { t } = useTranslation()
  const { id } = useParams()
  const [visitor, setVisitor] = useState<Visitor>()
  const history = useNavigate()
  const { createCRUDAlert } = useContext(AlertContext)
  const { selectedCompanyData } = useGlobalSelectedCompany()

  const getVisitor = async () => {
    if (id || isViewMode) {
      const visitor = await getVisitorById(id || viewId)
      setVisitor(visitor)
    }
  }

  const onSubmit = async (data) => {
    var validData: { group: any, companyId?: any } = {
      group: data.group,
    }
    if (getRole() === 'TECNOIT') {
      validData = {
        ...validData,
        companyId: selectedCompanyData.id,
      }
    }
    if (id) {
      const result = await editVisitor(id, validData)
      if (result) {
        createCRUDAlert(ROUTES.VISITORS, data.name, 'edited')
        goToVisitors(history)
      }
      return
    }
  }

  useEffect(() => {
    getVisitor()
  }, [])

  return (
    <Container
      sx={{
        mt: isViewMode ? '' : '96px',
      }}
    >
      <AlertComponent isView={isViewMode} />
      <Box
        component="div"
        sx={{
          color: '#FF5E1E',
          display: 'flex',
          gap: '10px',
          alignItems: 'center',
          justifyContent: 'flex-start',
          mb: isViewMode ? '12px' : '24px',
        }}
      >
        <DomainIcon sx={{ width: '30.55px', height: '28px' }} />
        <Typography
          variant="h6"
          sx={{
            fontWeight: '500',
            fontSize: '20px',
            lineHeight: '160%',
            letterSpacing: ' 0.15px',
            color: ' #FF5E1E',
            marginTop: '-1px',
          }}
        >
          {t('visitor.title')}
        </Typography>
      </Box>
      <Box>
        <EditVisitorForm
          data={visitor!}
          onSubmit={onSubmit}
          isViewMode={isViewMode}
          viewId={viewId}
        />
      </Box>
    </Container>
  )
}
export default EditVisitor
