import { useContext, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Box, Container, Typography } from '@mui/material'
import PollIcon from '@mui/icons-material/Poll'

import useProtectedPage from 'src/hooks/useProtectedPage'
import { AlertComponent } from 'src/components/Alert/AlertComponent'
import { ActivityContext } from 'src/context/ActivityContext'
import { getCompanyById } from 'src/service/companyService'
import { useGlobalSelectedCompany } from 'src/context/GlobalSelectedCompanyContext'

export const Settings = () => {
    useProtectedPage()
    const { t } = useTranslation()
    const { openDrawer } = useContext(ActivityContext)
    const { selectedCompanyData } = useGlobalSelectedCompany()
    const [navigationTime, setNavigationTime] = useState('');
    const [limitVelocity, setLimitVelocity] = useState('');
    const [directVisitors, setDirectVisitors] = useState('');

    const getData = async () => {
        const data = await getCompanyById(selectedCompanyData.id)
        if (data) {
            setNavigationTime(data?.urlController[0]?.url)
            setLimitVelocity(data?.urlController[1]?.url)
            setDirectVisitors(data?.urlController[2]?.url)
        }
    }

    useEffect(() => {
        getData()
    }, [navigationTime, limitVelocity, directVisitors])

    return (
        <Container
            sx={{
                mt: '96px',
                '.MuiDataGrid-row': {
                    minHeight: '62px!important',
                    '.MuiDataGrid-cell': {
                        paddingTop: '8px',
                        paddingBottom: '8px',
                        minHeight: '62px!important',
                    },
                },
                width: openDrawer ? 'calc(100% - 280px)' : '100%',
            }}
        >
            <AlertComponent />
            <Box
                component="div"
                sx={{
                    color: '#FF5E1E',
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'space-between',
                    mb: '24px',
                }}
            >
                <Box sx={{ display: 'flex', gap: '8px' }}>
                    <PollIcon />
                    <Typography
                        variant="h6"
                        sx={{
                            fontWeight: 500,
                            fontSize: '20px',
                            lineHeight: '160%',
                            letterSpacing: ' 0.15px',
                            color: ' #FF5E1E',
                            marginTop: '-3px',
                        }}
                    >
                        {t('settings.header')}
                    </Typography>
                </Box>
            </Box>
            <Box
                sx={{
                    mb: '40px',
                    display: 'flex',
                    flexWrap: 'wrap',
                    flexDirection: {
                        xs: 'column',
                        sm: 'column',
                        md: 'row',
                        lg: 'row',
                        xl: 'row',
                    },
                    gap: '16px',
                    width: openDrawer ? 'calc(100% - 280px)' : '100%',
                }}
            >
                <h3>{t('company.form.input.label.navigation-time')}</h3>
                <iframe src={navigationTime} title="navigationTime" width="100%" height="800px" />
            </Box>
            <Box
                sx={{
                    mb: '40px',
                    display: 'flex',
                    flexWrap: 'wrap',
                    flexDirection: {
                        xs: 'column',
                        sm: 'column',
                        md: 'row',
                        lg: 'row',
                        xl: 'row',
                    },
                    gap: '16px',
                    width: openDrawer ? 'calc(100% - 280px)' : '100%',
                }}
            >
                <h3>{t('company.form.input.label.limit-velocity')}</h3>
                <iframe src={limitVelocity} title="limitVelocity" width="100%" height="800px" />
            </Box>
            <Box
                sx={{
                    mb: '40px',
                    display: 'flex',
                    flexWrap: 'wrap',
                    flexDirection: {
                        xs: 'column',
                        sm: 'column',
                        md: 'row',
                        lg: 'row',
                        xl: 'row',
                    },
                    gap: '16px',
                    width: openDrawer ? 'calc(100% - 280px)' : '100%',
                }}
            >
                <h3>{t('company.form.input.label.direct-visitors')}</h3>
                <iframe src={directVisitors} title="directVisitors" width="100%" height="800px" />
            </Box>

        </Container>
    )
}
