import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import Box from '@mui/material/Box'
import { GridColumns } from '@mui/x-data-grid'
import { Table } from 'src/components/Table/Table'
import { goToEditVisitor } from 'src/routes/coordinator'
import { getVisitors } from 'src/service/visitorsService'
import { EditVisitorForm } from '../Form/EditVisitorForm'

export default function VisitorTable() {
  const { t } = useTranslation()
  const [tableKey, setTableKey] = useState(0)

  const getData = async (options: any) => {
    const data = await getVisitors(options)
    return { data: data.listVisitors, totalPages: data.page, size: data?.size }
  }

  const handleDelete = async (id, name) => {
    return
  }
  const columns: GridColumns = [
    {
      field: 'name',
      headerName: t('table.header.name'),
      width: 300,
      editable: false,
      headerAlign: 'left',
      align: 'left',
    },
    {
      field: 'cpf',
      headerName: 'CPF',
      width: 200,
      editable: false,
      headerAlign: 'left',
      align: 'left',
    },
    {
      field: 'email',
      headerName: 'E-mail',
      width: 230,
      editable: false,
      headerAlign: 'left',
      align: 'left',
    },
  ]

  return (
    <Box>
      <Table
        columns={columns}
        getData={getData}
        key={tableKey}
        handleDelete={handleDelete}
        titleKey="name"
        goTo={goToEditVisitor}
        defaultPageSize={15}
        model={'user'}
        Children={EditVisitorForm}
        size={'24px'}
        mr="25px"
      />
    </Box>
  )
}
