import { ChangePassword, UserManager } from 'src/models/user-model'
import { BASE_URL } from '../constants/url'
import { client } from './baseService'
import { log } from 'console'

export const getUsers = async (options?) => {
  try {
    const res = await client().get(`${BASE_URL}/admin`, options)
    return res.data
  } catch (error) {
    return false
  }
}

export const getUserProfile = async () => {
  try {
    const res = await client().get(`${BASE_URL}/profile`)
    return res.data
  } catch (error) {}
}

export const getUserById = async (
  id: string
): Promise<UserManager | undefined> => {
  try {
    const res = await client().get(`${BASE_URL}/admin/${id}`)
    return res.data
  } catch (error) {}
}

export const createUser = async (
  userManager: UserManager
): Promise<UserManager | boolean | { error: boolean; message: string }> => {
  const user = {
    companyId: userManager.companyId,
    name: userManager.name,
    email: userManager.email,
    phone: userManager.phone,
    password: userManager.password,
    role: userManager.role,
    profileImage: userManager.profileImage,
    roleId: userManager.roleId
  }
  try {
    const res = await client().post(`${BASE_URL}/admin`, user)
    const isDuplicatedEmail = (res as any)?.response?.status === 400
    const errorMessage = (res as any)?.response?.data // user.form.error.invalid.email ( tem no locale )
    // tem que retornar false para nao sair da pagina de formulario
    return res?.data ?? !isDuplicatedEmail
  } catch (error) {
    return false
  }
}

export const editUser = async (
  id: string,
  userManager: UserManager
): Promise<UserManager | boolean> => {
  try {
    const res = await client().patch(`${BASE_URL}/admin/${id}`, userManager)
    return res.data
  } catch (error) {
    return false
  }
}

export const recycleUserById = async (id: string): Promise<UserManager | boolean> => {
  try {
    const res = await client().patch(`${BASE_URL}/admin/${id}/enable`, {})
    return res.data
  } catch (error) {
    return false
  }
}

export const deleteUserById = async (
  id: string | number
): Promise<UserManager | boolean> => {
  try {
    const res = await client().delete(`${BASE_URL}/admin/${id}`)
    return true
  } catch (error) {
    return false
  }
}

export const changePassword = async (
  changePassword: ChangePassword
): Promise<any> => {
  const validPassword = {
    email: changePassword.email,
    password: changePassword.password,
    newPassword: changePassword.newPassword,
  }

  return client().post(`${BASE_URL}/admin/change-password`, validPassword)
}

export const getSecurityAlerts = async () => {
  try {
    const res = await client().get(`${BASE_URL}/security-alerts`)
    return res.data
  } catch (error) {
    return false
  }
}