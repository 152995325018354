import { useEffect } from 'react'
import { useParams } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { useForm } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import { Box } from '@mui/material'
import InputRHF from '../../../../components/RHF/InputRHF'
import {
  visitoryDefaultValues,
  visitorySchemaValidation,
} from '../../validators/visitorSchemaValidator'
import { Visitor } from 'src/models/visitor-model'
import { FooterBottons } from 'src/components/FormUtils/FooterButtons'
import { goToVisitors } from 'src/routes/coordinator'
import { set } from 'lodash'
import { useGlobalSelectedCompany } from 'src/context/GlobalSelectedCompanyContext'

interface EditVisitorFormProps {
  data: Visitor
  onSubmit: (data) => void
  isViewMode?: boolean
  viewId?: any
}

export const EditVisitorForm = ({
  data,
  onSubmit,
  isViewMode,
}: EditVisitorFormProps) => {
  const { t } = useTranslation()
  const { id } = useParams()
  const { selectedCompanyData } = useGlobalSelectedCompany()

  const form = useForm<any>({
    mode: 'all',
    defaultValues: visitoryDefaultValues,
    resolver: yupResolver(visitorySchemaValidation),
  })
  const { control, handleSubmit, reset, formState, setValue, trigger, watch } = form

  const resetForm = () => {
    reset({ companyName: '' })
  }

  useEffect(() => {
    if (data) {
      reset(data)
      setValue('id', data.id)
      setValue('name', data.name)
      setValue('email', data.email)
      setValue('phone', data.phone)
      setValue('cpf', data.cpf)
      setValue('marketingOptIn', data.marketingOptIn)
      setValue('createdAt', data.createdAt)
      Array.isArray(data.group) && data.group.map((group) => {
        if (group.companyId === selectedCompanyData.id) {
          setValue('group', group.groupName)
        }
      })
    }
  }, [data])

  return (
    <Box onSubmit={handleSubmit(onSubmit)} component="form">
      <Box
        sx={{
          display: 'grid',
          gridTemplateColumns: 'repeat(12, 1fr)',
          rowGap: isViewMode ? 0 : 1,
          columnGap: 2,
          gridColumn: 'span 12',
        }}
      >
        <InputRHF
          required
          disabled={true}
          control={control}
          label={t('visitor.form.input.label.name')}
          name="name"
          sx={{
            gridColumn: 'span 12',
            mb: isViewMode ? '4px' : '11px',
          }}
        />
        <InputRHF
          required
          disabled={true}
          control={control}
          label={t('visitor.form.input.label.email')}
          name="email"
          sx={{
            gridColumn: 'span 6',
            mb: isViewMode ? '4px' : '11px',
          }}
        />
        <InputRHF
          required
          disabled={true}
          control={control}
          label={t('visitor.form.input.label.cpf')}
          name="cpf"
          sx={{
            gridColumn: 'span 6',
            mb: isViewMode ? '4px' : '11px',
          }}
        />
        <InputRHF
          required
          disabled={true}
          control={control}
          label={t('visitor.form.input.label.phone')}
          name="phone"
          sx={{
            gridColumn: 'span 6',
            mb: isViewMode ? '4px' : '11px',
          }}
        />
        <InputRHF
          required
          disabled={false}
          control={control}
          label={t('visitor.form.input.label.group')}
          name="group"
          placeholder={t('visitor.form.input.placeholder.group')}
          sx={{
            gridColumn: 'span 6',
            mb: isViewMode ? '4px' : '11px',
          }}
        />
      </Box>


      {!isViewMode && (
        <FooterBottons
          isValid={!formState.isValid}
          formIsValid={formState.isValid}
          isEdit={Boolean(id)}
          goTo={goToVisitors}
          reset={() => resetForm()}
          size="100%"
          buttonName="company.button.create"
        />
      )}
    </Box>
  )
}
