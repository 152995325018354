import { BASE_URL } from '../constants/url'
import { client } from './baseService'

export const getVisitors = async (options?) => {
  try {
    const res = await client().get(`${BASE_URL}/visitors`, options)
    return res.data
  } catch (error) {
    return false
  }
}

export const getVisitorById = async (id: string) => {
  try {
    const res = await client().get(`${BASE_URL}/visitors/${id}`)
    return res.data
  } catch (error) {
    return false
  }
}

export const editVisitor = async (id: string, visitor) => {
  try {
    const res = await client().post(`${BASE_URL}/visitors/${id}/group`, visitor)
    return res.data
  } catch (error) {
    return false
  }
}