import AppsIcon from '@mui/icons-material/Apps'
import CampaignOutlinedIcon from '@mui/icons-material/CampaignOutlined'
import ContactSupportOutlinedIcon from '@mui/icons-material/ContactSupportOutlined'
import MapOutlinedIcon from '@mui/icons-material/MapOutlined'
import OndemandVideoRoundedIcon from '@mui/icons-material/OndemandVideoRounded'
import PersonOutlineOutlinedIcon from '@mui/icons-material/PersonOutlineOutlined'
import PollIcon from '@mui/icons-material/Poll'
import QuestionAnswerRoundedIcon from '@mui/icons-material/QuestionAnswerRounded'
import SettingsInputAntennaIcon from '@mui/icons-material/SettingsInputAntenna'
import SmartphoneIcon from '@mui/icons-material/Smartphone'
import WorkspacesOutlinedIcon from '@mui/icons-material/WorkspacesOutlined'
import SettingsOutlinedIcon from '@mui/icons-material/SettingsOutlined';
import Groups3OutlinedIcon from '@mui/icons-material/Groups3Outlined';

import { EmpresaIcon } from 'src/components/Icons/EmpresaIcon'
import { IMenuGroup, IMenuItem } from 'src/models/menuItem-model'

export const getMenuItems = (permissions: any): IMenuItem[] | IMenuGroup[] => ([
  {
    type: 'group',
    title: '',
    roles: ['TECNOIT'],
    items: [
      {
        type: 'item',
        path: '/empresas',
        component: '',
        isPrivate: true,
        icon: <EmpresaIcon />,
        label: 'layout.sideBar.label.companies',
        roles: ['TECNOIT'],
        disabled: !permissions?.company?.includes("company:list")
      },
      {
        type: 'item',
        path: '/enriquecimentos',
        component: '',
        isPrivate: true,
        icon: <PollIcon />,
        label: 'layout.sideBar.label.enrichment',
        roles: ['TECNOIT'],
        disabled: !permissions?.enrichment?.includes("enrichment:list")
      },
      {
        type: 'item',
        path: '/configuracoes',
        component: '',
        isPrivate: true,
        icon: <SettingsOutlinedIcon />,
        label: 'laytout.sidebar.label.settings',
        roles: ['TECNOIT'],
        disabled: !permissions?.enrichment?.includes("enrichment:list")
      },
    ],
  },
  {
    type: 'group',
    title: '',
    roles: ['TECNOIT', 'ADMIN'],
    items: [
      {
        type: 'item',
        path: '/usuarios',
        component: '',
        isPrivate: true,
        icon: <PersonOutlineOutlinedIcon />,
        label: 'layout.sideBar.label.user',
        roles: ['TECNOIT', 'ADMIN'],
        disabled: !permissions?.admin?.includes("admin:list")
      },
      {
        type: 'item',
        path: '/dashboard',
        component: '',
        isPrivate: true,
        icon: <AppsIcon />,
        label: 'layout.sideBar.label.dashboard',
        roles: ['TECNOIT', 'ADMIN'],
        disabled: !permissions?.dashboard?.includes("dashboard:view")
      },
      {
        type: 'item',
        path: '/visitantes',
        component: '',
        isPrivate: true,
        icon: <Groups3OutlinedIcon />,
        label: 'layout.sideBar.label.visitors',
        roles: ['TECNOIT', 'ADMIN'],
        disabled: !permissions?.admin?.includes("admin:list")
      },
    ],
  },
  {
    type: 'group',
    title: 'layout.sideBar.label.access_management',
    roles: ['TECNOIT', 'ADMIN'],
    items: [
      {
        type: 'item',
        path: '/gerenciamento/portais',
        component: '',
        isPrivate: true,
        icon: <SmartphoneIcon />,
        label: 'layout.sideBar.label.gate',
        roles: ['TECNOIT', 'ADMIN'],
        disabled: !permissions?.portal?.includes("portal:list")
      },
      {
        type: 'item',
        path: '/gerenciamento/pontos',
        component: '',
        isPrivate: true,
        icon: <SettingsInputAntennaIcon />,
        label: 'layout.sideBar.label.point',
        roles: ['TECNOIT', 'ADMIN'],
        disabled: !permissions?.accesspoint?.includes("accesspoint:list")
      },
      {
        type: 'item',
        path: '/gerenciamento/grupo-de-pontos',
        component: '',
        isPrivate: true,
        icon: <WorkspacesOutlinedIcon />,
        label: 'layout.sideBar.label.groups.point',
        roles: ['TECNOIT', 'ADMIN'],
        disabled: !permissions?.groups?.includes("groups:list")
      },
      {
        type: 'item',
        path: '/gerenciamento/campanhas',
        component: '',
        isPrivate: true,
        icon: <CampaignOutlinedIcon />,
        label: 'layout.sideBar.label.campaigns',
        roles: ['TECNOIT', 'ADMIN'],
        disabled: !permissions?.campaign?.includes("campaign:list")
      },
      {
        type: 'item',
        path: '/gerenciamento/mapa',
        component: '',
        isPrivate: true,
        icon: <MapOutlinedIcon />,
        label: 'layout.sideBar.label.colorMap',
        roles: ['TECNOIT', 'ADMIN'],
        disabled: !permissions?.heatmap?.includes("heatmap:view")
      },
    ],
  },
  {
    type: 'group',
    title: 'layout.sidebar.label.support',
    roles: ['TECNOIT', 'ADMIN'],
    items: [
      {
        type: 'item',
        path: '/gerenciamento/suporte',
        component: '',
        isPrivate: true,
        icon: <ContactSupportOutlinedIcon />,
        label: 'layout.sidebar.label.open_ticket',
        roles: ['TECNOIT', 'ADMIN'],
        disabled: false
      },
      {
        type: 'item',
        path: '/gerenciamento/video-aulas',
        component: '',
        isPrivate: true,
        icon: <OndemandVideoRoundedIcon />,
        label: 'layout.sidebar.label.training_video',
        roles: ['TECNOIT', 'ADMIN'],
        disabled: false
      },
      {
        type: 'item',
        path: '/gerenciamento/perguntas-frequentes',
        component: '',
        isPrivate: true,
        icon: <QuestionAnswerRoundedIcon />,
        label: 'layout.sidebar.label.faqs',
        roles: ['TECNOIT', 'ADMIN'],
        disabled: false
      },
    ],
  },
])
